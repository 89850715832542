'use client'
import LandingMenuLink from '@/app/(landing)/landing/components/LandingMenuLink'
import { Button } from '@nextui-org/button'
import { NavbarContent, Navbar as NextUiNavbar } from '@nextui-org/navbar'
import NextLink from 'next/link'
import React from 'react'

export default function Navbar({
  merchantLogo,
  isFooter = false,
}: {
  merchantLogo: React.JSX.Element
  isFooter?: boolean
}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      maxWidth={'xl'}
      isBordered={!isFooter}
      className={'text-base bg-white fixed'}
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={'w-40 md:w-44 h-full'}>
        <div className='relative w-full h-full flex flex-row items-center'>
          {merchantLogo}
        </div>
      </div>
      <NavbarContent justify={'end'}>
        <div
          className={
            'hidden lg:flex flex-row gap-6 grow max-w-[620px] lg:mr-8'
          }
        >
          <LandingMenuLink href={'https://jobmojito.com/'} onClick={closeMenu} target='_blank'>Home</LandingMenuLink>
          <LandingMenuLink href={'https://jobmojito.com/support'} onClick={closeMenu} target='_blank' >Support</LandingMenuLink>
        </div>

        <Button
          aria-label='Login or Register'
          href={'/login'}
          as={NextLink}
          className={'border-1'}
          color={'primary'}
          size={'md'}
          radius={'sm'}
        >
          Login & Sign up
        </Button>
      </NavbarContent>
    </NextUiNavbar>
  )
}
